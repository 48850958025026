import { withImmer } from 'jotai-immer'
import { atomWithStorage } from 'jotai/utils'
import type { Message } from '~/lib/core/types'

type Chat = {
  id: string
  title: string
  messages: Message[]
}

type Data = Chat[]

export const chatsAtom = withImmer(atomWithStorage<Data>('chats', []))

export const openaiApiKeyAtom = withImmer(atomWithStorage<string>('openaiApiKey', ''))
